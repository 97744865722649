body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: content-box;
}

html {
    scroll-behavior: smooth;
    --antd-wave-shadow-color: #ffc12d;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    color: #151515;
}

.ant-dropdown-menu {
    border-radius: 0px;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
    background-color: #ffc12d;
}
