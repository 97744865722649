.react-multiple-carousel__arrow {
    opacity: 0;
}
.react-multi-carousel-list:hover .react-multiple-carousel__arrow {
    opacity: 1;
}

.react-multi-carousel-dot-list {
    margin-bottom: 10px;
}

.react-multi-carousel-dot button {
    border: 0px;
    background-color: silver;
    height: 10px;
    width: 10px;
}

.react-multi-carousel-dot--active button {
    background-color: white;
}
